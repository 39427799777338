import { Checklist, AccountBalanceWallet } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PaymentIcon from '@mui/icons-material/Payment';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@mui/material';
import {
  GridToolbarContainer,
  GridRowSelectionModel,
  GridToolbar as DefaultGridToolbar,
  GridRowsProp
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';

import { Company, CompanyIban, DocumentType, InvoiceStages } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  gridToolbarContainerStyle,
  gridToolbarStyle,
  defaultGridToolbarStyle,
  gridToolbarOutsideContainerStyle
} from 'styles/components/DataGridStyle';

import { companyAtom, selectedCompanyIdAtom } from 'state/state';

import { Modal } from '../Modal/Modal';

interface GridToolbarProps {
  readonly handleAddClick?: () => void;
  readonly handleDeleteClick?: () => void;
  readonly handleExportForPaymentClick?: () => void;
  readonly handleExportExcelClick?: () => void;
  readonly handleUploadInvoice?: (
    fileData: FileList | File[],
    field: string
  ) => Promise<void>;
  readonly handleAccountClick?: () => void;
  readonly handlePayClick?: () => void;
  readonly handleApproveClick?: () => void;
  readonly handleRejectClick?: () => void;
  readonly handleDownloadClick?: () => void;
  readonly handleCreateClick?: () => void;
  readonly handleMarkAsTrustedClick?: () => void;
  readonly entries: GridRowsProp;
  readonly selectedRows?: GridRowSelectionModel;
  readonly isExportDisabled?: boolean;
  readonly isDefaultToolbarHidden?: boolean;
  readonly isDeleteDisabled?: boolean;
  readonly deleteButtonTooltip?: string;
  readonly companyIbans?: CompanyIban[];
  readonly companies?: Company[];
  readonly isOnAllCompanies?: boolean;
}

export const GridToolbar = ({
  handleAddClick,
  handleDeleteClick,
  handleExportForPaymentClick,
  handleExportExcelClick,
  handleUploadInvoice,
  handleAccountClick,
  handlePayClick,
  handleApproveClick,
  handleRejectClick,
  handleDownloadClick,
  handleCreateClick,
  handleMarkAsTrustedClick,
  entries,
  selectedRows,
  isExportDisabled,
  isDefaultToolbarHidden,
  isDeleteDisabled,
  deleteButtonTooltip,
  companyIbans,
  companies,
  isOnAllCompanies
}: GridToolbarProps) => {
  const { translate } = useTranslations();
  const [company] = useAtom(companyAtom);
  const [selectedCompanyId, setSelectedCompanyId] = useAtom(
    selectedCompanyIdAtom
  );

  const {
    isOpen: isSelectCompanyIdModalOpen,
    openModal: openSelectCompanyIdModal,
    closeModal: closeSelectCompanyIdModal
  } = useModal();

  const justifyContent =
    selectedRows && selectedRows.length > 0 ? 'space-between' : 'flex-end';
  const noRowsAreSelected = selectedRows && selectedRows.length === 0;
  const filteredEntries = entries.filter((entry: any) =>
    selectedRows?.includes(entry.id)
  );
  const shouldDisableDelete = filteredEntries.some(
    (entry: any) => !entry.isDeletable
  );
  const shouldDisablePay = !filteredEntries.every(
    (entry: any) =>
      entry.stage === InvoiceStages.APPROVED &&
      entry.isTrusted &&
      !entry.isPaid &&
      !entry.isPrepaid
  );
  const shouldShowAlreadyPaidTooltip = filteredEntries.some(
    (entry: any) => entry.isPaid || entry.isPrepaid
  );
  const isNotApprovedOrPaid = filteredEntries.some(
    (entry) =>
      entry.stage !== InvoiceStages.APPROVED &&
      entry.stage !== InvoiceStages.FINALIZED
  );
  const isAlreadyBooked = filteredEntries.some((entry) => entry.isAccounted);
  const shouldDisableBook = isNotApprovedOrPaid || isAlreadyBooked;
  const shouldDisableApprove = !filteredEntries.every(
    (entry: any) => entry.isApprovable
  );
  const isNoDocumentType = filteredEntries.some(
    (entry: any) => entry.documentType === DocumentType.NO_DOCUMENT
  );
  const isNotExportable = !filteredEntries.every(
    (entry: any) => entry.isExportable
  );
  const isIbanNotExportable = !(companyIbans || []).some(
    (iban) => iban.isExportable
  );
  const shouldDisableExport =
    noRowsAreSelected ||
    shouldDisablePay ||
    isNoDocumentType ||
    isIbanNotExportable ||
    isNotExportable;
  const shouldDisableDownload = filteredEntries.some(
    (entry: any) =>
      entry.stage === InvoiceStages.UPLOADED ||
      entry.documentType === DocumentType.NO_DOCUMENT
  );

  const deleteButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableDelete || isDeleteDisabled) {
      return deleteButtonTooltip;
    }

    return '';
  }, [selectedRows, entries, translate]);

  const payButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldShowAlreadyPaidTooltip) {
      return translate('messages.disabledPayInvoicesAlreadyPaid');
    }
    if (shouldDisablePay) {
      return translate('messages.disabledPayInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const bookButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (isNotApprovedOrPaid) {
      return translate('messages.disabledBookedInvoicesNotApprovedOrPaird');
    }
    if (isAlreadyBooked) {
      return translate('messages.disabledBookedInvoicesAlreadyBooked');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const approveButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableApprove) {
      return translate('messages.disabledApproveInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const rejectButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableApprove) {
      return translate('messages.disabledRejectInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const exportButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (isIbanNotExportable) {
      return translate('messages.disabledExportNotExportable', {
        companyName: company?.name || ''
      });
    }
    if (isNoDocumentType) {
      return translate('messages.disabledExportNoDocumentType');
    }
    if (shouldDisablePay) {
      return translate('messages.disabledPayInvoices');
    }
    if (isNotExportable) {
      return translate('messages.disabledExportNotPayable');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const downloadButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableDownload) {
      return translate('messages.disabledDownloadInvoices');
    }
    return '';
  }, [selectedRows, entries, translate]);

  useEffect(() => {
    if (!companies?.length) {
      return;
    }
    setSelectedCompanyId(companies[0].id);
  }, [companies?.length]);

  return (
    <Box
      sx={{
        ...gridToolbarContainerStyle
      }}
    >
      <Box sx={gridToolbarOutsideContainerStyle}>
        {handleDeleteClick && (
          <Tooltip title={deleteButtonTooltipText}>
            <span>
              <Button
                onClick={handleDeleteClick}
                color="error"
                disabled={
                  noRowsAreSelected || shouldDisableDelete || isDeleteDisabled
                }
                startIcon={
                  <DeleteTwoToneIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem'
                    }}
                  />
                }
              >
                {translate('buttons.delete')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleApproveClick && (
          <Tooltip title={approveButtonTooltipText}>
            <span>
              <Button
                onClick={handleApproveClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableApprove}
                startIcon={
                  <Checklist
                    sx={{
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.approve')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleRejectClick && (
          <Tooltip title={rejectButtonTooltipText}>
            <span>
              <Button
                onClick={handleRejectClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableApprove}
                startIcon={
                  <PlaylistRemoveIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.reject')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handlePayClick && (
          <Tooltip title={payButtonTooltipText}>
            <span>
              <Button
                onClick={handlePayClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisablePay}
                startIcon={
                  <PaymentIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.pay')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleAccountClick && (
          <Tooltip title={bookButtonTooltipText}>
            <span>
              <Button
                onClick={handleAccountClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableBook}
                startIcon={
                  <AccountBalanceWallet
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.book')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleExportForPaymentClick && (
          <Tooltip title={exportButtonTooltipText}>
            <span>
              <Button
                color="primary"
                onClick={handleExportForPaymentClick}
                disabled={shouldDisableExport}
                startIcon={
                  <SystemUpdateAltIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.exportForPayment')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleDownloadClick && (
          <Tooltip title={downloadButtonTooltipText}>
            <span>
              <Button
                color="primary"
                onClick={handleDownloadClick}
                disabled={noRowsAreSelected || shouldDisableDownload}
                startIcon={
                  <DownloadIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.download')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleMarkAsTrustedClick && (
          <Tooltip title={downloadButtonTooltipText}>
            <span>
              <Button
                color="primary"
                onClick={handleMarkAsTrustedClick}
                disabled={noRowsAreSelected || shouldDisableDownload}
                startIcon={
                  <HowToRegIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.markAsTrusted')}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          ...gridToolbarContainerStyle,
          justifyContent: isDefaultToolbarHidden ? 'flex-end' : 'space-between'
        }}
      >
        {!isDefaultToolbarHidden && (
          <DefaultGridToolbar sx={defaultGridToolbarStyle} />
        )}
        <GridToolbarContainer sx={{ ...gridToolbarStyle, justifyContent }}>
          {handleAddClick && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              {translate('buttons.add')}
            </Button>
          )}
          {handleExportExcelClick && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleExportExcelClick}
              disabled={isExportDisabled}
            >
              {translate('buttons.exportExcel')}
            </Button>
          )}
          {handleCreateClick && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                if (isOnAllCompanies) {
                  openSelectCompanyIdModal();
                  return;
                }
                handleCreateClick();
              }}
            >
              {translate('buttons.create')}
            </Button>
          )}
          {handleUploadInvoice && (
            <Button
              color="primary"
              startIcon={<FileUploadIcon />}
              component="label"
            >
              {translate('buttons.upload')}
              <input
                hidden
                accept=".pdf, .png, .jpg, .jpeg, .heic"
                type="file"
                name="invoice"
                multiple
                onChange={(e) => {
                  if (!handleUploadInvoice) {
                    return;
                  }
                  handleUploadInvoice(e.target.files as FileList, 'button');
                  e.target.value = '';
                }}
              />
            </Button>
          )}
        </GridToolbarContainer>
      </Box>
      {companies?.length ? (
        <Modal
          headerTitle={translate('labels.createDocument')}
          isOpen={isSelectCompanyIdModalOpen}
          hide={closeSelectCompanyIdModal}
          maxWidth="sm"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <FormControl fullWidth>
              <InputLabel>{`${translate('labels.company')}*`}</InputLabel>
              <Select
                label={translate('labels.company')}
                value={selectedCompanyId || companies[0].id}
                onChange={(e) => setSelectedCompanyId(Number(e.target.value))}
              >
                {companies.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{ alignSelf: 'flex-end' }}
              onClick={() => {
                closeSelectCompanyIdModal();
                if (handleCreateClick) {
                  handleCreateClick();
                }
              }}
            >
              {translate('buttons.create')}
            </Button>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};
