import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, Grid } from '@mui/material';
import { FieldArray, ArrayHelpers } from 'formik';
import { ReactNode, useCallback } from 'react';

import { useTranslations } from 'context/TranslationContext';

import {
  arrayFieldFormWrapper,
  arrayFieldWrapper,
  fullFieldWidth
} from 'styles/components/Common';

interface CustomFormikFieldArrayProps<T> {
  name: string;
  values: T[];
  isEdit: boolean;
  initialItem: Partial<T>;
  children: (index: number, value: T) => ReactNode;
  checkIsRemoveDisabled?: (index: number) => boolean;
}

export const CustomFormikFieldArray = <T,>({
  name,
  values,
  isEdit,
  initialItem,
  children,
  checkIsRemoveDisabled
}: CustomFormikFieldArrayProps<T>): JSX.Element => {
  const { translate } = useTranslations();

  const evaluateDisableRow = useCallback(
    (index: number) => {
      return checkIsRemoveDisabled
        ? isEdit && checkIsRemoveDisabled(index)
        : isEdit;
    },
    [checkIsRemoveDisabled, isEdit]
  );

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: ArrayHelpers) => {
        const handleAddField = () => {
          arrayHelpers.push({ ...initialItem });
        };

        const handleRemoveField = (index: number) => () => {
          arrayHelpers.remove(index);

          if (values.length === 1 && index === 0) {
            handleAddField();
          }
        };

        return (
          <Box sx={{ ...arrayFieldWrapper, gap: 0 }}>
            {values.map((value: T, index: number) => {
              const isRemoveRolDisabled = evaluateDisableRow(index);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Box sx={fullFieldWidth} key={index}>
                  <Box sx={arrayFieldFormWrapper}>
                    <Box sx={fullFieldWidth}>{children(index, value)}</Box>
                    <RemoveCircleOutlineIcon
                      color={isRemoveRolDisabled ? 'error' : 'disabled'}
                      sx={{
                        cursor: isRemoveRolDisabled ? 'pointer' : 'default',
                        mb: 4
                      }}
                      onClick={
                        isRemoveRolDisabled
                          ? handleRemoveField(index)
                          : () => {}
                      }
                    />
                  </Box>
                  {index === values.length - 1 && (
                    <Grid container justifyContent="center">
                      <Button
                        sx={{ mb: 2 }}
                        disabled={!isEdit}
                        onClick={handleAddField}
                      >
                        + {translate('buttons.add')}
                      </Button>
                    </Grid>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      }}
    />
  );
};
