import { useCallback } from 'react';

import { IbanService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useIbanController = () => {
  const { interceptRequest } = useInterceptor();

  const { getIbansByCompanyId } = IbanService;

  const getIbansByCompany = useCallback(
    (companyId: number) => interceptRequest(getIbansByCompanyId, {}, companyId),
    [getIbansByCompanyId]
  );

  return { getIbansByCompany };
};
