/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyIbanListResponse } from '../models/CompanyIbanListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IbanService {

    /**
     * This method returns the ibans for given company id
     * @param companyId The company id
     * @returns CompanyIbanListResponse OK
     * @throws ApiError
     */
    public static getIbansByCompanyId(
        companyId: number,
    ): CancelablePromise<CompanyIbanListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/ibans',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
