import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Tooltip, Button, CircularProgress } from '@mui/material';
import { Worker } from '@react-pdf-viewer/core';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import { useInvoiceController } from 'api/controllers/InvoiceController';

import { Invoice, InvoiceStages, OpenAPI } from 'openapi';

import { Layout } from 'components/Layout/Layout';
import { PdfViewerWithWatermark } from 'components/PdfViewerWithWatermark/PdfViewerWithWatermark';

import { useTranslations } from 'context/TranslationContext';

import { ACCESS_TOKEN, PDF_JS_WORKER_URL } from 'utils/constants/constants';
import { InvoiceEventType } from 'utils/enums/Invoice';
import { getRouteForBackNavigation } from 'utils/helpers/filtersHelpers';
import {
  getEventData,
  getSSERequestOptions,
  getUrl
} from 'utils/helpers/serverSentEvents';

import {
  zoomButtonsWrapper,
  mediumVerificationFieldWidth,
  verificationContentWrapper,
  fullWidth
} from 'styles/pages/InvoiceVerificationStyle';

import { filterTypeAtom, userAtom } from 'state/state';

import { InvoiceVerificationStepper } from './InvoiceVerificationStepper';

export const InvoiceVerification = React.memo((): JSX.Element => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();

  const [filterType] = useAtom(filterTypeAtom);
  const [user] = useAtom(userAtom);

  const { getInvoiceFile, getSingleInvoiceById, rempromptInvoice } =
    useInvoiceController();

  const { translate } = useTranslations();

  const [currentInvoice, setCurrentInvoice] = useState<Invoice | null>(null);
  const [eventSource, setEventSource] = useState<EventSourcePolyfill>();
  const [triggeredReprompt, setTriggeredReprompt] = useState<boolean>(false);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const rotatePluginInstance = rotatePlugin();

  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const token = useMemo(() => localStorage.getItem(ACCESS_TOKEN), []);
  const state = useMemo(() => localStorage.getItem('state'), []);

  const [pdfFileUrl, setPdfFileUrl] = useState<string>('');

  const isCreatedDocument = !currentInvoice?.filePath;

  const getInvoiceUrlInfo = useCallback(
    async (invoiceId: number) => {
      const invoiceFileData = await getInvoiceFile(invoiceId);

      setPdfFileUrl(invoiceFileData.url);
    },
    [getInvoiceFile]
  );

  const getInvoice = useCallback(async () => {
    const invoice = await getSingleInvoiceById(Number(companyId), Number(id));

    if (invoice.stage === InvoiceStages.UPLOADED && !triggeredReprompt) {
      navigate(`/companies/${companyId}/invoices`);
    }

    setCurrentInvoice(invoice);
    setTriggeredReprompt(false);
  }, [companyId, id, getSingleInvoiceById, triggeredReprompt]);

  const handleInvoiceEvent = useCallback(
    async (data: string) => {
      const { eventType, eventId } = getEventData(data);
      if (eventType === InvoiceEventType.INVOICE_UPDATE && eventId === id) {
        getInvoice();
      }
    },
    [getInvoice]
  );

  // Effects

  useEffect(() => {
    const result = new EventSourcePolyfill(
      getUrl(OpenAPI, getSSERequestOptions(user?.id)),
      {
        headers: {
          Authorization: `Bearer ${token}` || ''
        }
      }
    );

    setEventSource(result);

    return () => {
      result.close();
    };
  }, [token]);

  useEffect(() => {
    if (!eventSource) {
      return;
    }
    eventSource.onmessage = (e) => handleInvoiceEvent(e.data);
  }, [eventSource, handleInvoiceEvent]);

  useEffect(() => {
    getInvoice();
  }, [id]);

  useEffect(() => {
    if (!isCreatedDocument) {
      getInvoiceUrlInfo(Number(id));
    }
  }, [isCreatedDocument]);

  // TODO: Change eventually to a better way to check if the file is a pdf
  const isPdf = useMemo(
    () => currentInvoice?.filePath?.includes('.pdf'),
    [currentInvoice?.filePath]
  );

  const reprompt = useCallback(async () => {
    setTriggeredReprompt(true);
    await rempromptInvoice(currentInvoice?.id as number);
  }, [rempromptInvoice, currentInvoice, setTriggeredReprompt]);

  const refreshInvoice = useCallback(async () => {
    const invoice = await getSingleInvoiceById(
      Number(companyId),
      Number(currentInvoice?.id)
    );
    setCurrentInvoice(invoice);
  }, [companyId, currentInvoice]);

  const isRepromptDisabled = useMemo(
    () => currentInvoice?.stage !== InvoiceStages.DRAFT,
    [currentInvoice]
  );

  const handleNavigateBack = useCallback(() => {
    const route = getRouteForBackNavigation(
      state as string,
      filterType,
      companyId
    );

    navigate(route);
  }, [navigate, companyId]);

  return (
    <Layout
      styles={{
        paddingTop: '1rem',
        display: currentInvoice ? 'block' : 'none'
      }}
    >
      {currentInvoice && (
        <>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button
              sx={{ mb: isCreatedDocument ? 2 : 0 }}
              startIcon={<ArrowBackIcon />}
              onClick={handleNavigateBack}
            >
              {translate('buttons.back')}
            </Button>
            {!isCreatedDocument && (
              <Tooltip
                title={
                  isRepromptDisabled
                    ? translate('messages.repromptDisabled')
                    : ''
                }
              >
                <span>
                  <Button
                    startIcon={<ReplayIcon />}
                    disabled={isRepromptDisabled}
                    onClick={reprompt}
                  >
                    {translate('buttons.reprompt')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
          <Box sx={verificationContentWrapper}>
            {!isCreatedDocument && (
              <Worker workerUrl={PDF_JS_WORKER_URL || ''}>
                {pdfFileUrl ? (
                  <Box sx={mediumVerificationFieldWidth}>
                    {isPdf ? (
                      <>
                        <PdfViewerWithWatermark
                          fileUrl={pdfFileUrl}
                          plugins={[zoomPluginInstance, rotatePluginInstance]}
                          watermarkText={currentInvoice.stage as string}
                        />
                        <Box sx={zoomButtonsWrapper}>
                          <RotateBackwardButton />
                          <ZoomOutButton />
                          <ZoomPopover />
                          <ZoomInButton />
                          <RotateForwardButton />
                          <a
                            href={pdfFileUrl}
                            download={`Invoice-${currentInvoice.shortNameCompany}.pdf`}
                          >
                            <Tooltip title={translate('buttons.exportPdf')}>
                              <Button variant="text">
                                <FileDownloadIcon />
                              </Button>
                            </Tooltip>
                          </a>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          maxHeight: 'calc(100vh - 200px)'
                        }}
                      >
                        <img
                          src={pdfFileUrl}
                          alt="Preview invoice"
                          style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            maxHeight: 'calc(100vh - 200px)'
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ width: '50%' }}>
                    <CircularProgress
                      sx={{ position: 'absolute', top: '45%' }}
                    />
                  </Box>
                )}
              </Worker>
            )}
            {currentInvoice.stage === InvoiceStages.UPLOADED ? (
              <Box sx={{ width: '50%' }}>
                <CircularProgress
                  sx={{ position: 'absolute', top: '40%', left: '77%' }}
                />
              </Box>
            ) : (
              <Box
                sx={
                  isCreatedDocument ? fullWidth : mediumVerificationFieldWidth
                }
              >
                <InvoiceVerificationStepper
                  currentInvoice={currentInvoice}
                  triggeredReprompt={triggeredReprompt}
                  setCurrentInvoice={setCurrentInvoice}
                  refreshInvoice={refreshInvoice}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </Layout>
  );
});
