import { COMPANY_PARAMETER } from 'utils/constants/constants';

export enum AppRoutesEnum {
  HOME = '/',
  NOT_FOUND = '/not-found',
  INVOICES = '/documents',
  INVOICES_APPROVAL = '/documents-approval',
  INVOICES_ACCOUNTING = '/documents-accounting',
  INVOICES_PAYMENT = '/documents-payment',
  COMPANY_INVOICES = `/companies/${COMPANY_PARAMETER}/documents`,
  COMPANY_INVOICES_APPROVAL = `/companies/${COMPANY_PARAMETER}/documents-approval`,
  COMPANY_INVOICES_ACCOUNTING = `/companies/${COMPANY_PARAMETER}/documents-accounting`,
  COMPANY_INVOICES_PAYMENT = `/companies/${COMPANY_PARAMETER}/documents-payment`,
  INVOICE_VERIFICATION = `/companies/${COMPANY_PARAMETER}/documents/:id`,
  ADD_COMPANY = '/companies/add',
  COMPANY_DETAILS = `/companies/${COMPANY_PARAMETER}/details`,
  COMPANY_DASHBOARD = `/companies/${COMPANY_PARAMETER}/dashboard`,
  DASHBOARD = '/dashboard',
  COMPANY_COUNTERPARTIES = `/companies/${COMPANY_PARAMETER}/counterparties`,
  COMPANY_EXPENSE_TYPES = `/companies/${COMPANY_PARAMETER}/expense-types`,
  COMPANY_BUDGETING = `/companies/${COMPANY_PARAMETER}/budget`,
  COMPANY_PAYROLL = `/companies/${COMPANY_PARAMETER}/payroll`,
  COMPANY_APPROVALS = `/companies/${COMPANY_PARAMETER}/approvals`,
  USER_MANAGEMENT = '/user-management',
  COMPANY_USER_MANAGEMENT = `/companies/${COMPANY_PARAMETER}/user-management`,
  INVOICE_VERIFICATION_STEPPER = `/companies/${COMPANY_PARAMETER}/documents/:id/verification-stepper`,
  COMPANY_COUNTERPARTIES_NOT_TRUSTED = `/companies/${COMPANY_PARAMETER}/not-trusted`
}
