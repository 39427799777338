import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FilterListIcon from '@mui/icons-material/FilterList';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCounterPartyController } from 'api/controllers/CounterPartyController';

import {
  Counterparty,
  CounterpartyCreateUpdate,
  CounterpartyFilters,
  CounterpartyQueryFilters,
  CounterpartySortColumns,
  ExpenseType,
  Iban,
  SortOrder
} from 'openapi';

import { CounterPartiesFilterPanel } from 'components/CompanySettings/CounterParties/CounterPartiesFilterPanel';
import { CreateUpdateCounterParty } from 'components/forms/CreateUpdateCounterParty';
import { ListHoverGridCell } from 'components/shared/gridCells/ListHoverGridCell';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { Modal } from 'components/shared/Modal/Modal';

import { useTranslations } from 'context/TranslationContext';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';
import { useModal } from 'hooks/useModal';
import { usePagination } from 'hooks/usePagination';

import {
  ACTIONS,
  CHECK,
  DEFAULT_GRID_ROW_HEIGHT,
  IBANS_LIST,
  TRUSTED
} from 'utils/constants/constants';
import { INITIAL_COUNTERPARTY_FILTERS } from 'utils/constants/counterparty';
import {
  COUNTERPARTIES_FILTERS_EXPANDED_HEIGHT,
  FILTER_BAR_HEIGHT
} from 'utils/constants/filters';
import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { CounterpartyFilterType } from 'utils/enums/CounterpartyFilterType';
import { getCounterPartyColumns } from 'utils/helpers/counterPartyHelper';
import { getMappedCounterParties } from 'utils/mappers/counterParty';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import { modalButtonsWrapper } from 'styles/pages/InvoiceVerificationStyle';

import { filterTypeAtom } from 'state/state';

export const CounterParties = () => {
  const { companyId } = useParams();
  const { translate } = useTranslations();

  const initialPage = INITIAL_PAGINATION_PROPS.page;
  const initialPageSize = INITIAL_PAGINATION_PROPS.pageSize;
  const [totalCounterParties, setTotalCounterParties] = useState<number>(0);

  const { paginationModel, handlePaginationModelChange } = usePagination({
    page: initialPage,
    pageSize: initialPageSize,
    rowCount: totalCounterParties
  });

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();
  const {
    isOpen: isTrustModalOpen,
    openModal: openTrustModal,
    closeModal: closeTrustModal
  } = useModal();
  const { isOpen, closeModal, openModal } = useModal();

  const {
    createNewCounterParty,
    editCounterParty,
    deleteCounterParties,
    getFilteredCounterpartiesByCompanyId,
    getCounterpartyFilters,
    trustCounterpartiesByIds
  } = useCounterPartyController();
  const { getCompanyRegistrationNumberValue } = useDataGridHelpers();
  const { getExpenseTypes } = useAdditionalFieldsController();

  const [filterType] = useAtom(filterTypeAtom);

  const [counterParties, setCounterParties] = useState<Counterparty[]>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [filters, setFilters] = useState<CounterpartyQueryFilters>(
    INITIAL_COUNTERPARTY_FILTERS
  );
  const [filtersData, setFiltersData] = useState<CounterpartyFilters>();
  const [areFiltersVisible, setAreFiltersVisible] = useState<boolean>(false);
  const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
    []
  );
  const [selectedSingleCounterPartyId, setSelectedSingleCounterPartyId] =
    useState<number>();

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const hasSortModel = !!sortModel.length;
    const { field, sort } = hasSortModel
      ? sortModel[0]
      : { field: '', sort: '' };

    setFilters((prevFilters) =>
      hasSortModel
        ? {
            ...prevFilters,
            sortBy: field as CounterpartySortColumns,
            sortOrder: sort?.toUpperCase() as SortOrder
          }
        : { ...prevFilters, ...INITIAL_COUNTERPARTY_FILTERS }
    );
  }, []);

  const fetchCounterParties = useCallback(
    async (data: CounterpartyQueryFilters) => {
      const result = await getFilteredCounterpartiesByCompanyId(
        Number(companyId),
        {
          ...data,
          pageNumber: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          sortBy: data.sortBy,
          sortOrder: data.sortOrder?.toUpperCase() as SortOrder,
          isTrusted:
            filterType === CounterpartyFilterType.NotTrusted ? false : undefined
        }
      );

      const mappedCounterparties = getMappedCounterParties(
        result.counterparties
      );

      setTotalCounterParties(result.totalElements);
      setCounterParties(mappedCounterparties);
    },
    [
      getFilteredCounterpartiesByCompanyId,
      paginationModel,
      companyId,
      filterType
    ]
  );

  const fetchCounterPartyFilters = useCallback(async () => {
    const data = await getCounterpartyFilters(Number(companyId));
    setFiltersData(data);
  }, [getCounterpartyFilters, companyId]);

  const fetchDropdownValues = useCallback(async () => {
    const expenseTypeOptionValues = await getExpenseTypes(Number(companyId));
    setExpenseTypeOptions(expenseTypeOptionValues);
  }, [getExpenseTypes, companyId]);

  useEffect(() => {
    fetchDropdownValues();
    fetchCounterPartyFilters();
  }, []);

  useEffect(() => {
    fetchCounterParties(filters);
  }, [paginationModel, filters, filterType]);

  const [counterpartyForEdit, setCounterPartyForEdit] = useState<
    Counterparty | undefined
  >();

  const handleEditClick = (params: GridCellParams) => {
    if (params.field !== CHECK && params.field !== ACTIONS) {
      const { bic, ...reducedCounterParty } = params.row;
      setCounterPartyForEdit(reducedCounterParty);
      openModal();
    }
  };

  const handleCloseModals = () => {
    setSelectedSingleCounterPartyId(undefined);
    closeDeleteModal();
    closeTrustModal();
  };

  const handleDeleteClick = async () => {
    await deleteCounterParties(
      Number(companyId),
      selectedSingleCounterPartyId
        ? [selectedSingleCounterPartyId]
        : (selectedRows as number[])
    );
    fetchCounterParties(filters);
    fetchCounterPartyFilters();
    handleCloseModals();
  };

  const handleTrustCounterparties = async () => {
    handleCloseModals();
    await trustCounterpartiesByIds(Number(companyId), selectedRows as number[]);
    fetchCounterParties(filters);
  };

  const handleCloseModal = () => {
    setCounterPartyForEdit(undefined);
    fetchCounterParties(filters);
    closeModal();
  };

  const handleSubmitForm = async (values: CounterpartyCreateUpdate) => {
    const newIbansList = values.ibanList
      ?.map((iban) => {
        const newIban = { ...iban };
        // @ts-ignore
        delete newIban.tmpId;

        return newIban;
      })
      .filter((iban) => iban.iban);

    const body = {
      ...values,
      name: values.name!.trim(),
      ibanList: newIbansList,
      registrationNumber: values.isWithoutRegistrationNumber
        ? ''
        : values.registrationNumber,
      isWithoutRegistrationNumber:
        values.registrationNumber === ''
          ? true
          : values.isWithoutRegistrationNumber
    };

    try {
      counterpartyForEdit
        ? await editCounterParty(
            Number(companyId),
            Number(counterpartyForEdit.id),
            body
          )
        : await createNewCounterParty(Number(companyId), body);

      handleCloseModal();
      fetchCounterParties(filters);
      fetchCounterPartyFilters();
      fetchDropdownValues();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const modalTitle = useMemo(
    () =>
      counterpartyForEdit
        ? `${translate('labels.editCounterParty')}: ${counterpartyForEdit.name}`
        : translate('labels.addCounterParty'),
    [translate, counterpartyForEdit]
  );

  const shouldShowMultiSelectInfo = useMemo(() => {
    return (
      selectedRows.length > 1 && selectedSingleCounterPartyId === undefined
    );
  }, [selectedRows, selectedSingleCounterPartyId]);

  const columns = useMemo(
    () =>
      getCounterPartyColumns(translate, getCompanyRegistrationNumberValue).map(
        (column) => {
          if (column.field === IBANS_LIST) {
            return {
              ...column,
              renderCell: ({ row }: GridCellParams) => {
                return (
                  <ListHoverGridCell
                    list={row.ibanList?.map((iban: Iban) => iban.iban)}
                  />
                );
              }
            };
          }
          if (column.field === ACTIONS) {
            return {
              ...column,
              renderCell: ({ row }: any) => (
                <Tooltip
                  placement="left"
                  title={
                    !row.isDeletable
                      ? translate('messages.disabledDeleteCounterparty')
                      : ''
                  }
                >
                  <span>
                    <GridActionsCellItem
                      disabled={!row.isDeletable}
                      icon={
                        <DeleteTwoToneIcon
                          sx={(theme) => ({
                            color: row.isDeletable
                              ? theme.palette.error.main
                              : 'default',
                            fontSize: '1.5rem'
                          })}
                          titleAccess={translate('labels.delete')}
                        />
                      }
                      label="Delete"
                      onClick={() => {
                        setSelectedSingleCounterPartyId(row.id);
                        openDeleteModal();
                      }}
                    />
                  </span>
                </Tooltip>
              )
            };
          }
          if (column.field === TRUSTED) {
            return {
              ...column,
              renderCell: ({ row }: GridCellParams) => (
                <HowToRegIcon
                  htmlColor={row.isTrusted ? green[400] : grey[400]}
                />
              )
            };
          }
          return column;
        }
      ),
    [translate, getCompanyRegistrationNumberValue]
  );

  const handleApplyFilters = (data: CounterpartyFilters) => {
    setFilters(data);
  };

  const handleShowFilters = () => {
    setAreFiltersVisible((previousValue) => !previousValue);
  };

  const shouldShowTrustMassAction = useMemo(() => {
    return filterType === CounterpartyFilterType.NotTrusted;
  }, [filterType]);

  const containerHeight = areFiltersVisible
    ? COUNTERPARTIES_FILTERS_EXPANDED_HEIGHT
    : FILTER_BAR_HEIGHT;
  const height = `calc(100% - ${containerHeight}px)`;

  return counterParties ? (
    <Stack sx={commonDataGridContainerStyle}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{ width: '10%' }}
          variant="outlined"
          color="primary"
          onClick={handleShowFilters}
        >
          <FilterListIcon />
          {translate('buttons.filters')}
        </Button>
      </Box>
      <Stack sx={{ ...commonDataGridContainerStyle, height }}>
        <CounterPartiesFilterPanel
          handleApplyFilters={handleApplyFilters}
          filtersData={filtersData}
          areFiltersVisible={areFiltersVisible}
        />
        <Paper
          elevation={4}
          sx={{
            ...commonDataGridContainerStyle,
            marginTop: '1rem',
            minHeight: 'calc(100% - 150px)'
          }}
        >
          <Modal
            headerTitle={modalTitle}
            isOpen={isOpen}
            hide={handleCloseModal}
          >
            <CreateUpdateCounterParty
              initialValues={counterpartyForEdit}
              handleSubmit={handleSubmitForm}
              expenseTypeOptions={expenseTypeOptions}
            />
          </Modal>

          <ConfirmationDialog
            title={translate('buttons.markAsTrusted')}
            isOpen={isTrustModalOpen}
            onClose={handleCloseModals}
            size="sm"
            onConfirm={handleTrustCounterparties}
          >
            {shouldShowMultiSelectInfo
              ? translate('messages.trustCounterpartiesConfirmation', {
                  numberOfCounterparties: selectedRows.length.toString()
                })
              : translate('messages.trustCounterpartyConfirmation')}
          </ConfirmationDialog>

          <DataGrid
            rows={counterParties}
            columns={columns}
            getRowId={(row) => row.id || 0}
            checkboxSelection
            rowHeight={DEFAULT_GRID_ROW_HEIGHT}
            onCellClick={handleEditClick}
            disableRowSelectionOnClick
            sx={invoicesDataGrid}
            paginationMode="server"
            sortingMode="server"
            onRowSelectionModelChange={setSelectedRows}
            paginationModel={paginationModel}
            pageSizeOptions={[]}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={totalCounterParties}
            onSortModelChange={handleSortModelChange}
            slots={{
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
                handleAddClick: openModal,
                handleDeleteClick: openDeleteModal,
                handleMarkAsTrustedClick:
                  shouldShowTrustMassAction && openTrustModal,
                selectedRows,
                isDefaultToolbarHidden: true,
                entries: counterParties,
                deleteButtonTooltip: translate(
                  'messages.disabledDeleteCounterparties'
                )
              }
            }}
            localeText={{
              noRowsLabel: translate('labels.noData')
            }}
          />
          {isDeleteModalOpen && (
            <Modal
              headerTitle={translate('titles.delete')}
              isOpen={isDeleteModalOpen}
              hide={handleCloseModals}
              maxWidth="sm"
            >
              <Typography variant="body1" mb={2}>
                {shouldShowMultiSelectInfo
                  ? translate('messages.deleteCounterpartiesConfirmation', {
                      numberOfCounterparties: selectedRows.length.toString()
                    })
                  : translate('messages.deleteCounterpartyConfirmation')}
              </Typography>
              <Box sx={modalButtonsWrapper}>
                <Button variant="outlined" onClick={handleCloseModals}>
                  {translate('buttons.cancel')}
                </Button>
                <Button variant="contained" onClick={handleDeleteClick}>
                  {translate('buttons.delete')}
                </Button>
              </Box>
            </Modal>
          )}
        </Paper>
      </Stack>
    </Stack>
  ) : (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
