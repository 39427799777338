import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useUserController } from 'api/controllers/UserController';

import { User } from 'openapi';

import { CreateUpdateCompanyForm } from 'components/forms/CreateUpdateCompanyForm';

import { useCompanies } from 'context/CompanyContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

export const CompanyDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const { companiesList, removeCompany } = useCompanies();
  const { companyId } = useParams();
  const { getHomePageRoute } = useRouteSecurity();
  const { deleteCompany } = useCompanyController();
  const { getUsersByCompanyId } = useUserController();

  const [users, setUsers] = useState<User[]>([]);

  const getUsers = async () => {
    const response = await getUsersByCompanyId(Number(companyId));
    setUsers(response);
  };

  const onDelete = async () => {
    await deleteCompany(Number(companyId));
    navigate(getHomePageRoute());
    removeCompany(Number(companyId));
  };

  const companyDetails = useMemo(() => {
    return companiesList.find((company) => company.id === Number(companyId));
  }, [companiesList, companyId]);

  useEffect(() => {
    getUsers();
  }, [companyId]);

  return (
    <Box>
      {companyDetails && (
        <Box sx={mediumVerificationFieldWidth}>
          <CreateUpdateCompanyForm
            companyDetails={companyDetails}
            onDelete={onDelete}
            users={users}
          />
        </Box>
      )}
    </Box>
  );
};
